import {showModalMain,hideModalMain,showLoaderMain,hideLoaderMain} from "../../componentsHandlers.js"
$(document).ready(function() {
  $("#form").submit(async (e)=>{
    e.preventDefault()
    hideModalMain()
    const sitekey = $(e.target).data("site-key");
    grecaptcha.ready(function() {
      grecaptcha.execute(sitekey, {action: 'submit'}).then(async function(token) {
        showLoaderMain({})
        var user = await $("form").serializeArray().map((e)=>{return{[e.name]:e.value}});
        $.post('/login',{
          username:user[0].username,
          password:user[1].password,
          token
        }, function(data){
          let {isUser,isSuscriptor} = data;
          // console.log(data) 
          if(!isUser){
            hideLoaderMain()
            showModalMain("warning","Nombre de usuario o contraseña incorrectos, intente de nuevo")
          }
          else if(!isSuscriptor){
            hideLoaderMain()
            showModalMain("payment","Su cuenta no esta suscrita al servicio de NovelNews")
          }else window.location.href ="/create"
          
        }).catch((error)=>{
          let objErr = error.responseJSON 
          if(objErr) {
            if (objErr.type === "ErrorRobotDetected")
              showModalMain("error","Se denego la peticion de recuperacion de cuenta porque se detecto un comportamiento sospechoso",objErr.message)
            else
              showModalMain("error","Hubo un error al intentar comunicarnos con su mail, pruebe en otro momento","No se pudo realizar el envio del mail")
          }
          hideLoaderMain()
        })
      });
    });
    
  })
})